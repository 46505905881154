/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 */


/* Variables */

:root {
    /** Header styles **/
    --eco-border-radius: 30px;
}


/* Global Custom Fonts */

@font-face {
    font-family: 'AcuminRegular'; //This is what we are going to call
    src: url('../assets/fonts/Acumin-RPro.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AcuminBold'; //This is what we are going to call
    src: url('../assets/fonts/Acumin-BdPro.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AcuminItalic'; //This is what we are going to call
    src: url('../assets/fonts/Acumin-ItPro.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'AcuminBoldItalic'; //This is what we are going to call
    src: url('../assets/fonts/Acumin-BdItPro.otf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'AcuminBlack'; //This is what we are going to call
    src: url('../assets/fonts/Acumin-BlackPro.ttf');
    font-weight: 700;
    font-style: normal;
}

input,
button,
select,
textarea,
optgroup,
option,
p,
a {
    font-family: "AcuminRegular", sans-serif;
}

h1 {
    font-family: "AcuminBold", sans-serif;
    font-size: 22.7pt;
}

h2 {
    font-family: "AcuminRegular", sans-serif;
    font-size: 20pt;
}

h2 {
    font-family: "AcuminBold", sans-serif;
    font-size: 16pt;
}

p {
    font-family: "AcuminRegular", sans-serif;
    font-size: 13pt;
}

a {
    color: var(--ion-color-primary);
    text-decoration: none;
}

ion-content {
    --background: none;
    background-image: url('/assets/svg/background-light.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


/* Buttons */

ion-button {
    --box-shadow: none;
    text-transform: none;
}

ion-button::first-letter {
    text-transform: uppercase;
}

ion-button.button-solid {
    color: white;
}

ion-back-button {
    color: #161C33;
}

ion-item {
    --background: transparent;
}

ion-datetime {
    --background: #ffffff;
    --background-rgb: 255, 255, 255;
    color: var(--ion-color-primary-contrast);
}

.icon-help {
    cursor: pointer;
}

ion-button.icon-help-btn {
    margin: 0px 0px 0px 10px;
}


/* Header START */


/* Remove shadow from header*/

ion-header:after {
    background-image: none !important;
}


/* Add top left rounded curve */

ion-header:after {
    content: "" !important;
    position: absolute !important;
    bottom: -40px !important;
    left: -1px !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: var(--eco-border-radius) 0px 0px 0px;
    // background: white;
    box-shadow: 0px -20px 0 0 #f1f1f1;
}

ion-toolbar {
    --background: #F1F1F1;
}

ion-header.main-header:after {
    box-shadow: 0px -20px 0 0;
    color: var(--ion-color-primary);
}

ion-header.main-header ion-toolbar {
    --background: var(--ion-color-primary);
}

ion-header.main-header ion-toolbar ion-icon {
    color: white;
}


/* Header END*/


/* Titles */

ion-title {
    color: var(--ion-color-primary-contrast);
}

.ios ion-title.center-title {
    margin-left: 0;
}

.md ion-title {
    margin-left: -50px;
}


/* Toolbar top */

ion-toolbar {
    border-bottom-right-radius: var(--eco-border-radius);
}

ion-toolbar.main-toolbar {
    --background: var(--ion-color-primary);
    border-bottom-right-radius: unset;
}

ion-list.ios {
    background-color: white; // Fix: iOS menu background is dark.
}


/* Checkbox styles */

ion-checkbox {
    --background-checked: transparent;
    --checkmark-color: var(--ion-color-primary);
    --background: transparent !important;
}

ion-checkbox::part(container) {
    border-radius: 50%;
    border: 2px solid var(--ion-color-primary);
}

ion-tab-button {
    --color: var(--ion-color-primary-contrast);
    --color-focused: red;
    --color-selected: #12347C; // Hover
    font-weight: bold;
    background-color: white; // Fix: iOS background is dark
}


/* ion-select styles */

ion-select {
    --placeholder-opacity: 1 !important;
}

ion-action-sheet {
    --button-color: #000000 !important;
    --background: white !important;
    --button-background-selected: white !important;
}


/* Clear category button */

ion-col.clear-cat-btn-col {
    display: flex;
    flex-direction: row-reverse;
}

a.clear-cat-btn {
    cursor: pointer;
}


/* ion-searchbar */

ion-searchbar .searchbar-input-container input,
.product-item,
.business-item {
    border-color: #7E5D99 !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 15px !important;
}

ion-searchbar {
    --color: var(--ion-color-primary-contrast) !important;
}

ion-searchbar.business-searchbar .searchbar-input-container input {
    border-color: var(--ion-color-primary) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 15px !important;
}

.center-content-vertical {
    display: flex;
    align-items: center;
}

.text-capitalize-first:first-letter {
    text-transform: uppercase;
}

.max-width-ux {
    max-width: 600px;
}

.business-address-col {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.business-address-col p {
    font-size: 13px;
    color: #7A7B7B;
}


/* Swiper (slides) with photos START */

.swiper-photos {
    height: 15vh;
    border-radius: 25px;
}

.swiper-photos .slide-content-wrapper {
    width: 100%;
    height: auto;
}

.swiper-photos .slide-content-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: center;
}

.slides-arrow {
    color: white;
    font-size: 40px;
    position: absolute;
    top: 35%;
    z-index: 2;
}

.slides-arrow-back {
    left: 16px;
}

.slides-arrow-forward {
    right: 16px;
}

.swiper-pagination-bullet {
    color: white;
    background-color: white !important;
    width: 12px;
    height: 12px;
}


/* Swiper (slides) with photos END */

.business-product-categories ion-button {
    font-size: 13px;
}

.business-item::part(native) {
    padding: 0;
}

app-business-item {
    width: 100%;
    height: 100%;
    display: flex;
}

.business-item,
.product-item {
    margin-bottom: 10px;
}

app-product-item {
    display: flex;
    width: 100%;
}

.product-item .product-item-wrapper,
.business-item .business-item-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
}

ion-item.product-item::part(native) {
    margin: 0px;
    padding: 0px;
}

.product-item .product-item-wrapper ion-thumbnail,
.business-item .business-item-wrapper ion-thumbnail {
    margin: 0;
    height: 100%;
    display: flex;
    flex: 1 1 0;
}

.product-item .product-item-wrapper ion-thumbnail ion-img.padded-image,
.business-item .business-item-wrapper ion-thumbnail ion-img.padded-image {
    padding: 5px;
}

.product-item .product-item-wrapper ion-thumbnail img,
.business-item .business-item-wrapper ion-thumbnail img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.product-item .product-item-wrapper .product-data,
.business-item .business-item-wrapper .business-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 6 1 0;
    margin-left: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.product-item .product-item-wrapper .product-name,
.product-item .product-item-wrapper .product-description,
.product-item .product-item-wrapper .product-price,
.business-item .business-item-wrapper .business-name,
.business-item .business-item-wrapper .business-category {
    color: var(--ion-color-primary-contrast);
}

.product-item .product-item-wrapper .product-name {
    text-transform: none;
}

.product-item .product-item-wrapper .product-data .product-meta,
.business-item .business-item-wrapper .business-data .business-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.business-item .business-item-wrapper .business-data .business-address {
    font-size: 10pt;
}

.product-item .product-item-wrapper .product-price,
.business-item .business-item-wrapper .business-meta .business-hours {
    text-align: right;
}

// Product item active == false
.product-item .product-item-wrapper.product-inactive .product-name,
.product-item .product-item-wrapper.product-inactive .product-description,
.product-item .product-item-wrapper.product-inactive .product-price {
    color: #8A8B8D;
}

.product-item .product-item-wrapper.product-inactive .product-image {
    filter: opacity(50%);
}

.product-item .product-item-wrapper.product-inactive .product-unavailable-label {
    color: #A83B3E;
}


/* Menu styles  START*/

ion-menu ion-content {
    background: none !important;
    z-index: 10; // Prevents curved toolbar from being on top of content
}


/* Menu Profile image START */

.profile-name {
    padding: 10px;
}

.profile-name h2 {
    margin: 0;
}

.profile-img {
    padding: 10px;
}

.profile-edit-icon-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: var(--ion-color-primary-contrast);
    color: white;
    border-radius: 20px;
    padding: 2px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
}

.profile-edit-icon {
    margin: auto;
}

.profile-page-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


/* Menu Profile image edit END */

.round-top-left {
    border-top-left-radius: var(--eco-border-radius);
}

.round-top-right {
    border-top-right-radius: var(--eco-border-radius);
}


/* Menu buttons styles */

.profile-menu ion-button,
ion-button.eco-btn {
    font-size: 16px;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0px;
    color: #1e2648;
}

.profile-menu ion-button ion-icon {
    margin-right: 20px;
}


/* Menu styles  END */


/* Center ion-grid within ion-content START*/

.content-wrapper.screen-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-wrapper.screen-center .main-content {
    flex-grow: inherit;
}

.main-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-grid ion-row {
    width: 100% !important;
}


/* Center ion-grid within ion-content END*/

#logo {
    max-width: 200px;
    margin: auto;
}

.logo-internal {
    width: 80px;
    max-width: 200px;
    margin: auto;
}

.space-around-inline-btn {
    display: flex;
    justify-content: space-between;
}

.space-evenly-inline-btn {
    display: flex;
    justify-content: space-evenly;
}

.center-content {
    display: flex;
    justify-content: center;
}

.align-content-right {
    display: flex;
    justify-content: flex-end;
}

.subtitle {
    color: #cdcccc;
    font-weight: bold;
}

.privacy-statement {
    font-size: 10pt;
}

.error-text {
    color: var(--highlight-color-invalid) !important;
    font-size: 12px !important;
}

.tab-menu-toggle {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tab-menu-toggle ion-icon {
    font-size: 24px;
}

.item-edit-button {
    height: 100%;
    margin: 0;
}

.icon-help-btn {
    --padding-end: 10px;
}


/* Alert styles */

ion-alert .alert-wrapper {
    border-radius: var(--eco-border-radius) !important;
    background: white !important;
}

.alert-icon-delete,
.alert-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    display: block;
    margin: auto;
}

.alert-message {
    color: #1e2648;
    text-align: center;
}

.alert-button-confirm {
    background: var(--ion-color-primary) !important;
    color: white !important;
}

.alert-button-cancel {
    text-decoration: underline;
}

.profile-photo-action-btn {
    height: auto !important;
}

.custom-layout-column {
    display: flex;
    flex-direction: column;
}

.profile-photo-action-icon {
    font-size: 90px;
}

.profile-picture {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover !important;
    object-position: center;
}

.profile-avatar {
    width: 50px;
    height: 50px;
}


/* Custom Alert buttons */

.custom-alert-center-buttons .alert-button-group {
    justify-content: space-around !important;
}

.alert-button-no {
    background-image: url("../assets/svg/no-image.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.alert-button-yes {
    background-image: url("../assets/svg/yes-image.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ios .alert-button-no,
.ios .alert-button-yes {
    background-size: 44px;
    min-height: 44px;
}

.md .alert-button-no,
.md .alert-button-yes {
    min-height: 44px;
    min-width: 44px;
}

.wrong-credentials {
    color: red;
}

.business-distance {
    display: flex;
}

.business-hours {
    display: flex;
}


/* Business Categories Slider START */

.business-category {
    height: unset !important;
}

.business-category-item-wrapper {
    width: 100%;
    height: 100%;
}

.business-category-item-wrapper.selected {
    background-color:#52bcc070; /* Cambia al color rojo cuando está seleccionado */
    /* Otros estilos adicionales para la categoría seleccionada */
}


.business-category-item-wrapper {
    height: 100%;
    width: 100%;
    background-color: #F3F2EE;
    border-radius: 20px;
}


.business-category-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3px;
}

.business-category-item .cat-image {
    width: 40px;
    height: 40px;
    margin: 5px auto;
}

.business-category-item .cat-image::part(image) {
    width: 100%;
    object-fit: contain !important;
}

.business-category-item .cat-name {
    color: #000205;
    line-height: 1.2em !important;
    text-transform: initial;
    font-weight: bold;
    font-size: 8pt;
}


/* Business Categories Slider END */


/* Product Modal START */

.product-add-to-cart .product-image::part(image) {

  
    border-radius: 50%;
    // border: solid pink 3px;
    text-align: center;



}

.product-add-to-cart .product-image {
    width: 80px;
    height: 80px;
    margin: auto;
    // border: solid green 3px;
}

.product-add-to-cart .product-name {
    text-transform: none;
}

.product-add-to-cart .product-schedule-item {
    background-color: #AADCD8;
    color: #281B3D;
    border-radius: 15px;
}

.product-add-to-cart .product-schedule-item.schedule-item-selected {
    background-color: #ECECE7;
}

.product-add-to-cart ion-checkbox {
    --checkbox-background-checked: transparent;
    --checkmark-color: var(--ion-color-primary);
}

.product-add-to-cart ion-checkbox::part(container) {
    border-radius: 5px;
    border: 1px solid #7D5E97;
}

.product-comments {
    --background: #ECECE7;
    border-radius: 15px;
    --padding-top: 10px;
    padding-left: 10px;
}

.quantity-area {
    display: flex;
}

.quantity-area ion-button {
    width: 28px;
    height: 28px;
}

.quantity-area .qty-plus-minus-btn {
    color: white;
    font-size: 9pt;
}

.quantity-area .qty-plus-minus-btn::part(native) {
    padding: 5px;
}

.quantity-area .quantity {
    // color: var(--ion-color-primary-contrast);
    color: #1e2648;
    font-size: 14pt;
    text-align: center;
}

.quantity-area .quantity input {
    padding-left: 0px;
    padding-right: 0px;
    opacity: 1 !important;
}

#add-to-cart-fixed {
    background-color: white;
}

.add-to-cart-btn {
    color: white;
    text-transform: none;
    --padding-start: 2em;
    --padding-end: 2em;
    height: 35px;
}

ion-modal.calendar-modal {
    // Redondear esquinas
    --border-radius: 10px;
}

.center-modal {
    --width: calc(100% - 2em);
    align-items: center;
    --height: 80vh;
    --border-radius: var(--eco-border-radius);
}

.center-modal .ion-page {
    position: relative;
    display: block;
    contain: content;
    margin: 10px 5px 0px 5px;
}

.close-modal-button {
    position: absolute;
    right: 0;
}

.calendar-modal,
.time-modal {
    &.center {
        --width: calc(100% - 2em);
        align-items: center;
        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;
        }
    }
}

.calendar-modal.auto-height {
    --height: auto;
}

.calendar-modal.auto-height .ion-page {
    position: relative;
    display: block;
    contain: content;
}

.calendar-modal.auto-height .ion-page .inner-content {
    max-height: 80vh;
}

.selected-time {
    background-color: rgba(82, 189, 192, 0.7);
}

.selected-schedule {
    color: #9E7BAA;
}

.schedule-selected-date {
    text-transform: capitalize !important;
}

.schedule-selected-time {
    margin-left: 5px;
}


/* Product Modal END */


/* Cart page  START*/

.cart .cart-title {
    text-transform: uppercase;
    text-align: center;
}

.cart .item {
    display: flex;
    flex-direction: column;
    background-color: #E6F4F3;
    border-radius: 15px;
    padding: 10px;
}

.cart .item .item-data {
    display: flex;
}

.cart .item .item-actions-image {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.cart .item .product-image::part(image) {
    width: 100%;
    object-fit: cover !important;
    border-radius: 50%;
    // border: solid red 2px;
}

.cart .item .product-image {
    width: 65px;
    height: 65px;
    margin: auto;
    // border: solid blue 2px;
}

.cart .item .item-description-area {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.cart .item .item-actions {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.cart .item .item-description {
    color: #899A9A;
}

.cart .item .item-title {
    font-weight: bold;
}

.cart .item .action-remove {
    display: flex;
    justify-content: flex-end;
}

.cart .item .item-actions .price {
    display: flex;
    justify-content: center;
}

.cart .cart-total {
    border-top: #44c2c0 solid 3px;
}

.cart .cart-title-area {
    border-bottom: #44c2c0 solid 3px;
}

.cart .cart-title-area-top {
    border-top: #44c2c0 solid 3px;
}

.cart .cart-title-area2 {
    border-bottom: #142449 solid 3px;
}

.cart .cart-total ion-label {
    font-weight: bold;
}

.cart .cart-total .empty-cart-btn {
    margin-left: 15px;
}

#checkout .checkout-btn {
    width: auto;
    font-size: 16px;
}


/* Cart Page END */

.cart-badge {
    font-size: 11px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    background-color: #A83B3D;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-notification {
    --background: #A6DBD7;
    transform: translateY(-50px);
    color: var(--ion-color-primary-contrast)
}

.cart-notification::part(button),
.cart-notification {
    text-align: center;
    font-size: 13pt;
    text-transform: none;
}

.cart-notification::part(button) {
    background: var(--ion-color-primary);
    color: white;
    border-radius: 6px;
    margin-right: 10px;
}

// .cart-notification::part(container) {
//     flex-direction: column;
// }
// .cart-notification::part(message) {
//     display: none;
// }
.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0;
}

ion-modal.blue-background {
    --ion-background-color: rgb(215, 243, 255);
}

ion-modal.white-background {
    --ion-background-color: rgb(255, 255, 255);
}

.select-full-width {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 0 !important;
}


/* Address and Payment methods ion-item-sliders */

.eco-sliding-item.active,
.sliding-item.active,
.sliding-item.active .item-content {
    background: #f5f5f5;
}

.eco-sliding-item.inactive,
.sliding-item.inactive,
.sliding-item.inactive .item-content {
    background: #ffffff;
}

.eco-sliding-item,
.eco-sliding-item .item-content {
    border-radius: 60px;
}

.eco-sliding-item.item-sliding-active-slide .item,
.eco-sliding-item.item-sliding-active-slide.item-sliding-active-options-start .item {
    background-color: #eb445a;
}

.eco-sliding-item.item-sliding-active-slide.item-sliding-active-options-end .item {
    background-color: var(--ion-color-primary);
}

.eco-sliding-item ion-item-options ion-item-option {
    width: 100%;
}

.eco-sliding-item.item-sliding-active-slide.item-sliding-active-options-start {
    text-align: start;
}

.eco-sliding-item.item-sliding-active-slide.item-sliding-active-options-end {
    text-align: end;
}

.eco-sliding-item ion-item-options ion-item-option::part(native) {
    width: auto;
}

.eco-sliding-item .item-content {
    padding: 10px 0px;
    width: 100%;
}

.eco-sliding-item .item-actions {
    padding: 0;
}

.eco-sliding-item .sliding-item {
    --inner-padding-end: 0px;
}


/* Payment Method BEGIN */

.payment-method-status,
.notification-status {
    font-size: 12pt;
}

.notification-item.active .notification-status:before {
    background-color: #FCCF54;
}

.notification-item .notification-status:before {
    background-color: #F0F0ED;
}

.payment-method-item .payment-method-status,
.notification-item .notification-status {
    display: flex;
}

.notification-item .notification-status:before {
    display: block;
    content: "";
    margin-right: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    // margin-top: 5px;
}


/* Payment Method END */


/* Notifications BEGIN */

.notification-item {
    border-radius: var(--eco-border-radius);
}

.notification-item.active {
    background-color: #F0F0ED;
}

.notification-item ion-col {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

ion-img.img-circle {
    width: 40px;
    height: 40px;
    margin: 5px auto;
}

ion-img.img-circle::part(image) {
    width: 100%;
    object-fit: cover !important;
    border-radius: 50%;
}

.notification-business-name {
    text-transform: uppercase;
}

.notification-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.notification-description p {
    display: contents;
    font-size: inherit;
}

.notification-description,
.notification-date {
    font-size: 11pt;
    color: #161C33 !important;
}

.notification-status-col {
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
    align-items: center;
}

.notification-status {
    margin: 5px !important;
}

.notification-modal {
    color: var(--ion-color-primary-contrast);
}

.notification-modal::part(content) {
    max-width: 90%;
    border-radius: 15px;
}

.notification-date-time {
    display: flex;
    flex-direction: row;
}

.notification-date-time .notification-date {
    margin-right: 10px;
}

.notification-badge {
    position: absolute;
    top: 0;
    left: 0;
}

.notification-badge.badge-large {
    left: unset;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
}


/* Notifications END */


/* Category Swiper */

.swiper-category {
    width: fit-content !important;
}


/* Privacy Policy and Terms and Conditions modals */

.logo-small #logo {
    width: 60px;
}

.policy-modal h1 {
    font-size: 16pt;
}

.policy-modal p {
    font-size: 10pt;
    text-align: justify;
}

.policy-modal ul {
    padding-left: 20px;
}

.version-label {
    color: #bebebe;
    font-size: x-small;
}

.sc-ion-label-md-s h3,
.sc-ion-label-md-s h4,
.sc-ion-label-md-s h5,
.sc-ion-label-md-s h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
}

.sc-ion-label-md-s p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 13px;
    // line-height: 20px;
    text-overflow: inherit;
    overflow: inherit;
}

.alert-date {
    --background: yellow;
    color: #1e2648;
    --border-radius: 22px;
    font-size: large;
    font-weight: bold;
}

ion-toast.alert-date::part(button) {
    color: #1e2648;
    font-size: large;
    font-weight: bold;
    text-transform: none;
  }