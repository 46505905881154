ion-header.blue-theme:after {
    color: var(--ion-color-blue-theme) !important;
}

ion-toast.blue-theme {
    --background: var(--ion-color-blue-theme-tint) !important;
    transform: translateY(-50px);
    color: var(--ion-color-blue-theme-contrast)
}

ion-toast.blue-theme::part(button) {
    background: var(--ion-color-blue-theme) !important;
}

.blue-theme {
    ion-textarea.product-comments {
        --background: var(--ion-color-blue-theme-tint) !important;
        --placeholder-color: black !important;
    }
    ion-col.product-schedule-item {
        background-color: var(--ion-color-blue-theme-tint) !important;
    }
    ion-item {
        --highlight-background: var(--ion-color-blue-theme);
        --highlight-color-focused: var(--ion-color-blue-theme);
    }
    ion-toolbar,
    ion-button {
        --background: var(--ion-color-blue-theme) !important;
    }
    ion-chip {
        background: var(--ion-color-blue-theme) !important;
    }
    ion-input[type="number"] {
        background-color: transparent;
    }
    p.schedule-note {
        color: var(--ion-text-color);
    }
    button.calendar-day.calendar-day-active>div {
        background-color: black;
    }
}