ion-header.gray-theme:after {
    color: var(--ion-color-gray-theme) !important;
}

ion-toast.gray-theme{
    --background: var(--ion-color-gray-theme-tint) !important;
    transform: translateY(-50px);
    color: var(--ion-color-gray-theme-contrast)
}

ion-toast.gray-theme::part(button) {
        background: var(--ion-color-gray-theme) !important;
}

.gray-theme {

    ion-textarea.product-comments {
        --background: var(--ion-color-gray-theme-tint) !important;
        --placeholder-color: black !important;
    }

    ion-col.product-schedule-item {
        background-color: var(--ion-color-gray-theme-tint) !important;
    }

    ion-item {
        --highlight-background: var(--ion-color-gray-theme);
        --highlight-color-focused: var(--ion-color-gray-theme);
    }
    
    ion-toolbar,
    ion-button {
        --background: var(--ion-color-gray-theme) !important;
    }

    ion-chip {
        background: var(--ion-color-gray-theme) !important;
    }

    ion-input[type="number"] {
        background-color: transparent;
    }

    p.schedule-note {
        color: var(--ion-text-color);
    }

    button.calendar-day.calendar-day-active > div {
        background-color: black;
    }
}
